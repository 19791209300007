















import Vue from 'vue'
export default Vue.extend({
  props: {
    item: Object
  }
})















import Vue from 'vue'
import { Spin } from "ant-design-vue"
import { getNews } from '@/service/api'
import MNewsItem from '@/components/mobile/m-newsItem.vue'
export default Vue.extend({
	name: 'newsLists',
	data() {
		return {
			total: 0,
			currentPage: 1,
			pageSize: 7,
			allLists: [],
			newsLists: [],
			isLoading: false
		}
	},
	components: {
		Spin,
		MNewsItem
	},
	methods: {
		handleChange() {
			this.currentPage++
			this.getLists()
		},
		getLists() {
			const copy = JSON.parse(JSON.stringify(this.allLists))
			this.newsLists = copy.slice(0, Math.min((this.currentPage) * this.pageSize, this.total))
		},
		getNewsList() {
			const language = window.sessionStorage.getItem('language') || 'zh-CN'
			this.isLoading = true
			getNews(language).then(res => {
				this.allLists = res.rows
				this.allLists.forEach((item: {cover: string}) => {
					item.cover = item.cover.replace(/public.33.cn/g, 'public.yuan.org')
				})
				this.total = this.allLists.length
				this.getLists()
				this.isLoading = false
			}).catch(e => {
				this.isLoading = false
				throw Error(e)
			})
		}
	},
	created() {
		this.getNewsList()
	},
	watch: {
		$route(n, o) {
			// this.currentPage = 1
			if (n.query.lang !== o.query.lang) {
				this.currentPage = 1
			}
			this.getNewsList()
		}
	}
})
